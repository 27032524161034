<div class="login-body">
    <div class="login-panel ">
    </div>
    <div class="login-content">
        <img src="assets/images/Backoffice.png" alt="babylon-layout">
        <h3>Login To Your Employee Account</h3>
        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="login-input-wrapper">

                <input class="bg" type="text" pInputText placeholder="Username" name="username" required
                       [(ngModel)]="credentials.username">
                       <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="login-input-wrapper">
                <input class="bg" type="password" pInputText placeholder="Password" name="password" required
                       [(ngModel)]="credentials.password">
                       <i class="fa fa-key" aria-hidden="true"></i>
            </div>
            <button type="submit" [disabled]="!loginForm.form.valid" pButton label="{{'Sign In' | translate}}"
                    class="raised-btn rounded-btn">
            </button>
        </form>
    </div>
</div>

